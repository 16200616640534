<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col
        cols="11"
      >
        <v-img
          max-width="150"
          src="/assets/8_logo.svg"
        />

        <p class="text-subtitle-1 primary--text my-4 font-weight-bold">
          {{ $t('welcome.heading') }}
        </p>

        <p>
          {{ $t('welcome.text0') }}
        </p>
        <p>
          {{ $t('welcome.text1') }}
        </p>
        <p class="my-4">
          {{ $t('welcome.salutation') }}
        </p>
        <v-btn
          color="primary"
          @click="$emit('welcome-done')"
        >
          {{ $t('welcome.button') }}
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Welcome',
  data: () => ({
    //
  })
}
</script>
