<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col
        cols="11"
      >
        <v-img
          max-width="150"
          src="/assets/8_logo.svg"
        />

        <p class="text-subtitle-1 primary--text my-4 font-weight-bold">
          {{ $t('manual.heading') }}
        </p>
        <p class="text-h4">
          {{ $t('manual.subheading') }}
        </p>
        <p class="text-h4 primary--text">
          {{ $t('manual.head0') }}
        </p>
        <p>
          {{ $t('manual.text0') }}
        </p>
        <p class="text-h4 primary--text">
          {{ $t('manual.head1') }}
        </p>
        <p>
          {{ $t('manual.text1') }}
        </p>
        <p class="text-h4 primary--text">
          {{ $t('manual.head2') }}
        </p>
        <p>
          {{ $t('manual.text2') }}
        </p>
        <v-btn
          color="primary"
          @click="$emit('manual-done')"
        >
          {{ $t('manual.button') }}
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Manual',
  data: () => ({
    //
  })
}
</script>
