<template>
  <v-app>
    <v-app-bar
      v-if="show_survey"
      app
      color="primary"
      extension-height="5"
      height="80"
    >
      <p class="text-h5">
        {{ question.current_question + 1 }}: {{ question.text }}
      </p>
      <template #extension>
        <v-progress-linear
          color="#ffffff"
          :value="(question.current_question / question.total_questions) * 100"
        />
      </template>
    </v-app-bar>
    <v-main>
      <v-dialog v-model="show_help">
        <v-card class="bback">
          <v-card-text>
            <v-row>
              <v-spacer />
              <v-col
                cols="12"
                align="left"
              >
                <p class="text-h4">
                  {{ $t('manual.subheading') }}
                </p>
                <p class="text-h4 primary--text">
                  {{ $t('manual.head0') }}
                </p>
                <p>
                  {{ $t('manual.text0') }}
                </p>
                <p class="text-h4 primary--text">
                  {{ $t('manual.head1') }}
                </p>
                <p>
                  {{ $t('manual.text1') }}
                </p>
                <p class="text-h4 primary--text">
                  {{ $t('manual.head2') }}
                </p>
                <p>
                  {{ $t('manual.text2') }}
                </p>
                <v-btn
                  color="primary"
                  @click="show_help=false"
                >
                  {{ $t('manual.got_it') }}
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="120"
          width="12"
          color="primary"
        />
      </v-overlay>
      <div v-if="errored">
        <v-container>
          <v-row>
            <v-col>
              <v-alert type="error">
                {{ $t('app.error_occurred') }} {{ error_message }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-else>
        <Welcome
          v-if="show_welcome"
          @welcome-done="show_welcome=false; show_manual=true; scroll_to_top();"
        />
        <Manual
          v-if="show_manual"
          @manual-done="show_manual=false; show_survey=true; next_question()"
        />
        <ThankYou v-if="show_thankyou" />
        <v-container
          v-if="show_survey && Object.keys(question).length != 0"
          ma-0
          pt-0
          class="options_container"
        >
          <v-row
            v-for="(option, i) in question.options"
            :key="i"
          >
            <v-col
              cols="12"
              md="5"
            >
              <p>{{ option }}</p>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <v-slider
                ref="slider"
                v-model="answer_array[i]"
                :max="question.max_points"
                min="0"
                hide-details="true"
                step="1"
                thumb-label="always"
                :thumb-size="32"
                ticks="always"
                class="mt-8 ml-4 mr-4"
                @input="onOptionChange()"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>

    <v-footer
      v-if="show_survey"
      dark
      :color="statusbar_color"
      app
      class="ma-0 pa-0"
    >
      <v-container
        class="pa-0 ma-0 px-0"
        fluid
      >
        <v-row
          align="center"
          no-gutters
          class="px-4 py-4"
        >
          <v-col
            cols="3"
            class=""
          >
            <v-btn
              icon
              @click="show_help=true"
            >
              <v-icon>
                mdi-help-circle-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            align="center"
            class="pa-0"
          >
            <p class="ma-0">
              <v-icon
                v-if="sum_points != 23"
                color="primary"
              >
                mdi-alert
              </v-icon>
              <v-icon
                v-else
                color="success"
              >
                mdi-check-outline
              </v-icon>
              {{ sum_points }} / 23 {{ $t('app.points') }}
            </p>
          </v-col>
          <v-col
            align="right"
            class="pa-0"
            cols="3"
          >
            <v-btn
              :disabled="error_state"
              @click="post_answer(answer_array)"
            >
              {{ $t('app.next') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          style="background-color: #000000"
          class="py-2"
        >
          <v-spacer />
          <v-col
            align="center"
            cols="12"
          >
            <a
              :href="impress_url"
              target="_blank"
            >{{ $t('app.impress') }}</a>
            <v-divider
              class="mx-4"
              vertical
            />
            <a
              :href="privacy_url"
              target="_blank"
            >{{ $t('app.privacy') }}</a>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-footer>

    <footer v-else>
      <v-container>
        <v-row>
          <v-spacer />
          <v-col
            align="center"
            cols="12"
          >
            <a
              :href="impress_url"
              target="_blank"
            >{{ $t('app.impress') }}</a>
            <v-divider
              class="mx-4"
              vertical
            />
            <a
              :href="privacy_url"
              target="_blank"
            >{{ $t('app.privacy') }}</a>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </footer>
  </v-app>
</template>

<script>
import axios from 'axios'
import Welcome from './components/Welcome'
import Manual from './components/Manual'
import ThankYou from './components/ThankYou'
import Vue18in from './i18n'

export default {
  name: 'App',

  components: {
    Welcome,
    Manual,
    ThankYou
  },

  data: () => ({
    answer_array: [],
    current_step: 0,
    max_step: 0,
    sum_points: 0,
    token: null,
    question: {},

    errored: false,
    error_state: false,
    error_message: '',
    loading: true,

    statusbar_color: '#000000',

    api_url: null,
    impress_url: null,
    privacy_url: null,

    show_welcome: false,
    show_manual: false,
    show_survey: false,
    show_thankyou: false,
    show_help: false,
    flavor: null
  }),

  async mounted () {
    // inject config
    const response = await axios.get('/settings.json')
    this.api_url = response.data.PARTICIPATE_API_URL
    this.impress_url = response.data.IMPRESS_URL
    this.privacy_url = response.data.PRIVACY_URL

    // parse params
    const uri = window.location.search.substring(1)
    const params = new URLSearchParams(uri)

    // setup locales
    this.locale = params.get('locale')
    if (this.locale == null) {
      this.locale = 'de'
    }
    Vue18in.locale = this.locale

    this.token = params.get('token')
    if (this.token == null) {
      this.errored = true
      this.error_message = this.$t('app.error_token')
      this.loading = false
      return
    }

    // get survey with provided token
    axios.get(this.api_url + this.token)
      .then(response => {
        this.current_step = response.data.current_question
        this.max_step = response.data.total_questions
        this.show_welcome = true
        this.flavor = response.data.flavor
        if (['default_en', 'personal_en'].indexOf(this.flavor) > -1) {
          Vue18in.locale = 'en'
        } else if (['default_it', 'personal_it'].indexOf(this.flavor) > -1) {
          Vue18in.locale = 'it'
        } else {
          Vue18in.locale = 'de'
        }
      })
      .catch(error => {
        this.errored = true
        this.error_message = this.$t('app.error_unknown') + ' ' + error
        if (error.response) {
          this.error_message = this.$t('app.error_unknown') + ' ' + error.response.statusText
          if (error.response.status === 404) {
            this.error_message = this.$t('app.error_url')
          }
        }
      })
      .finally(() => (this.loading = false))
  },

  methods: {
    post_answer: function (response) {
      this.loading = true
      const params = {
        question_num: this.current_step,
        response: response
      }
      axios.post(this.api_url + this.token + '/next', params)
        .then(response => {
          this.current_step += 1
          this.current_results = null

          if (response.data >= this.max_step) {
            this.question = null
            this.loading = false
            this.show_thankyou = true
            this.show_survey = false
          } else {
            this.answer_array.fill(0)
            this.next_question()
          }
        })
        .catch(error => {
          this.errored = true
          this.error_message = this.$t('app.error_unknown') + ' ' + error
          if (error.response) {
            if (error.response.status === 404) {
              this.error_message = this.$t('app.error_not_found')
            } else {
              this.error_message = error.response.status + ': ' + this.$t('app.oops')
            }
          }
        })
    },
    next_question: function () {
      this.loading = true
      axios.get(this.api_url + this.token + '/next', {
        params: {
          next_q: this.current_step
        }
      })
        .then(response => {
          this.question = response.data
          this.answer_array = Array(this.question.max_step).fill(0)
          this.scroll_to_top()
        })
        .catch(error => {
          this.errored = true
          this.error_message = this.$t('app.error_unknown') + ' ' + error
          if (error.response) {
            if (error.response.status === 404) {
              this.error_message = this.$t('app.error_not_found')
            }
          }
        })
        .finally(() => (this.loading = false))
    },
    scroll_to_top: function () {
      this.$nextTick(() => {
        this.$vuetify.goTo(0, { duration: 500, offset: 0, easing: 'easeInOutCubic' })
      })
    },
    onOptionChange: function () {
      const currentSum = this.answer_array.reduce((a, b) => a + b, 0)
      this.sum_points = currentSum
      if (currentSum === this.question.max_points) {
        this.error_state = false
        this.statusbar_color = 'primary'
      } else {
        this.error_state = true
        this.statusbar_color = 'black'
      }
    }
  }

}
</script>

<style>
p {
    color: #FFFFFF !important;
}

.bback {
    background: #1b1e25 !important;
}

.options_container>div:nth-of-type(odd) {
  background: #1b1e25;
}
</style>
