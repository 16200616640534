<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col
        cols="11"
      >
        <v-img
          max-width="150"
          src="/assets/8_logo.svg"
        />

        <p class="text-subtitle-1 primary--text my-4 font-weight-bold">
          {{ $t('thankyou.heading') }}
        </p>
        <p class="text-h3">
          {{ $t('thankyou.subheading') }}
        </p>
        <p>
          {{ $t('thankyou.text') }}
        </p>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ThankYou',
  data: () => ({
    //
  })
}
</script>
